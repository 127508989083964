export const actionStatuses = {
  Created: 'created',
  Deleted: 'deleted',
  Changed: 'changed',
  Employee_appended: 'employeeAppended',
  Employee_deleted: 'employeeDeleted',
  Status_changed: 'changedStatus',
  Booking: 'booking',
  'Workspace created': 'created',
  'Workspace deleted': 'deleted',
  'Workspace updated': 'workspaceUpdated',
  'Workspace moved': 'changed',
  'Workspace status changed': 'changedStatus',
  'Workspace booked': 'booking',
  'Room updated': 'roomUpdated',
  'Employee attached to workspace': 'employeeAttached',
  'Employee detached from workspace': 'employeeDetached',
  'Office info changed': 'officeInfoChanged',
  'Office created': 'officeCreated',
  'Office deleted': 'officeDeleted',
  'Location added': 'locationAdded',
  'Floor added': 'floorAdded',
  'Floor deleted': 'floorDeleted',
  'Floor manager added': 'floorManagerAdded',
  'Floor manager deleted': 'floorManagerDeleted',
  'Floor manager changed': 'floorManagerChanged',
  'Floor HR added': 'floorHRAdded',
  'Floor HR deleted': 'floorHRDeleted',
  'Floor HR changed': 'floorHRChanged',
  'Workspace request created': 'requestCreated',
  'Workspace request deleted': 'requestDeleted',
  'Workspace request approved': 'requestApproved',
  'Workspace request rejected': 'requestRejected',
  'Workspace request delayed': 'requestDelayed',
  'Additional floor manager added': 'additionalFloorManagerAdded',
  'Additional floor manager deleted': 'additionalFloorManagerDeleted',
  'Additional floor manager changed': 'additionalFloorManagerChanged',
};
