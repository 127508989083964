import {inject, injectable} from 'inversify';
import {map, Observable} from 'rxjs';
import {
  Employee,
  EmployeeResponse,
  EmployeesListResponse,
  ManagementStaff,
  ManagementStaffResponse,
} from '@innowise-group/core';
import {GlobalContainerTypes} from '../../types';
import {EmployeesApi} from './employees-api.types';
import {Http} from '../http';
import {EmployeesNormalization} from '../employees-normalization';

@injectable()
class EmployeesApiService implements EmployeesApi {
  public static readonly type = GlobalContainerTypes.EmployeesApi;

  @inject('Http') private http: Http;
  @inject(GlobalContainerTypes.EmployeesNormalization) private employeesNormalization: EmployeesNormalization;

  public getEmployees(name: string, count?: number, page?: number): Observable<{employees: Employee[]; count: number}> {
    const params = this.createUrlSearchParams(name, count, page);
    return this.http.GET<EmployeesListResponse>(`/employees/?${params}`).pipe(
      map(({results, count}) => ({
        count,
        employees: results.map(this.employeesNormalization.normalizeEmployeeFromApi),
      })),
    );
  }

  public getEmployeeById(id: string): Observable<Employee> {
    return this.http
      .GET<EmployeeResponse>(`/employees/${id}/`)
      .pipe(map(this.employeesNormalization.normalizeEmployeeFromApi));
  }

  public getEmployeeByHrmId(id: string): Observable<Employee> {
    return this.http
      .GET<EmployeesListResponse>(`/employees/?hrm_id=${id}`)
      .pipe(map(({results}) => results.length && this.employeesNormalization.normalizeEmployeeFromApi(results[0])));
  }

  public getManagementStaffList(locationId?: string, officeId?: string): Observable<ManagementStaff[]> {
    const params = new URLSearchParams('');
    if (locationId && !officeId) {
      params.append('location_id', locationId);
    }
    if (locationId && officeId) {
      params.append('office_id', officeId);
      params.append('location_id', locationId);
    }
    return this.http
      .GET<ManagementStaffResponse[]>(`/employees/managers/?${params}`)
      .pipe(map((list) => list.map(this.employeesNormalization.normalizeManagementStaffFromApi)));
  }

  public getEmployeesWithoutSeats(
    name?: string,
    count?: number,
    page?: number,
    jiraOfficeId?: string,
  ): Observable<{employees: Employee[]; count: number}> {
    const params = this.createUrlSearchParams(name, count, page, jiraOfficeId);
    return this.http.GET<EmployeesListResponse>(`/employees/loose-employees/?${params}`).pipe(
      map(({results, count}) => ({
        count,
        employees: results.map(this.employeesNormalization.normalizeEmployeeFromApi),
      })),
    );
  }

  public getTotalEmployeesCount(): Observable<number> {
    return this.http.GET<{count: number}>(`employees/count/`).pipe(map(({count}) => count));
  }

  public getEmployeesCountWithoutBot(): Observable<number> {
    return this.http.GET<{count: number}>(`employees/bot-count/`).pipe(map(({count}) => count));
  }

  public getEmployeesWithoutBot(
    name?: string,
    count?: number,
    page?: number,
    jiraOfficeId?: string,
  ): Observable<{employees: Employee[]; count: number}> {
    const params = this.createUrlSearchParams(name, count, page, jiraOfficeId);
    return this.http.GET<EmployeesListResponse>(`employees/bot-not-installed/?${params}`).pipe(
      map(({results, count}) => ({
        count,
        employees: results.map(this.employeesNormalization.normalizeEmployeeFromApi),
      })),
    );
  }

  public getEmployeesCountWithoutSeats(): Observable<number> {
    return this.http.GET<{count: number}>(`/employees/loose-count/`).pipe(map(({count}) => count));
  }

  private createUrlSearchParams(name?: string, count?: number, page?: number, jiraOfficeId?: string): URLSearchParams {
    const params = new URLSearchParams('');

    name && params.append('preferred_name', name);
    jiraOfficeId && params.append('jira_office_id', jiraOfficeId);

    if (count && page) {
      params.append('limit', String(count));
      params.append('offset', String(count * (page - 1)));
    }

    return params;
  }
}

export default EmployeesApiService;
