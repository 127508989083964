import {useContext, useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {AppRoutes} from '../../constants/app.router';
import {useAuth} from './use-auth.hook';
import {PersonContext} from '@shared/core/services/authentication';
import {isNull} from 'lodash';

export const PrivateRoute: React.FC = ({children}) => {
  const {pathname, search} = useLocation();
  const {authenticated} = useAuth();
  const authenticatedValue = authenticated.getValue();
  const person = useContext(PersonContext);

  useEffect(() => localStorage.setItem('hotelling-path', pathname + search), [pathname, search]);

  return (
    <>
      {authenticatedValue ? (
        person?.isLocal || isNull(person) ? (
          <Navigate to={AppRoutes.NotFound} />
        ) : (
          children
        )
      ) : (
        <Navigate to={AppRoutes.Auth} />
      )}
    </>
  );
};
