export enum AppRoutes {
  Home = '/',
  Auth = '/sign-in',
  Offices = '/offices',
  Floors = '/floors',
  Requests = '/requests',
  Managers = '/managers',
  History = '/history',
  Employees = '/employees',
  Chatbot = '/chatbot',
  Geotrack = '/geotrack',
  Hrm = '/hrm',
  FloorEditor = '/floor-editor',
  NotFound = '/not-found',
}
